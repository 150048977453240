#kt_content_container {
    max-width: 100vw;
}

.content-section {
    width: 32.5%;
}

#kt_content {
    padding: 0;
}


/* .header-fixed.toolbar-fixed .wrapper {
    padding-top: calc(18px + var(--kt-toolbar-height));
} */

#kt_wrapper {
    padding-top: calc(18px + var(--kt-toolbar-height));
}

.instruktur-profile {
    width: 20px;
    border-radius: 50%;
    margin-right: 4px;
    cursor: pointer;
    transition: ease-in-out 0.3s;
}    

.instruktur-profile:hover {
    text-shadow: 0.2em 0.2em 0.5em rgba(40, 40, 40, 30%);
}

.card .jenis_pelatihan--jenjang_kompetensi {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* display: block; */
    /* width: 250px; */
    /* white-space: nowrap;
    text-overflow: ellipsis; */
}
.info-pelatihan--nama-diklat, .info-pelatihan--penyelenggara, .detil-pelatihan--lokasi {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.carousel-pelatihan--deskripsi-dokumentasi {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

#pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

#info_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-bottom: 10px;
}

#informasi_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

#detail_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

#jenis_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

#summary_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

#carousel_pelatihan_wrapper {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    z-index: 1;
}

.carousel_pelatihan img, .carousel_pelatihan iframe {
    border-radius: 4px;
}

.pelatihan-mendatang {
    height: 500px;
}

.modal-peserta .symbol img {
    border-radius: 50%;
}

#switch_mode {
    position: fixed;
    right: 30px;
    top: 18px;
    z-index: 1000;
}

/* Switch Mode */
.checkbox {
    opacity: 1;
    position: absolute;
}

.label {
    width: 45px;
    height: 25px;
    background-color: #eee;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    cursor: pointer;
}

.ball {
    width: 18px;
    height: 18px;
    background-color: white;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked+.label .ball {
    transform: translateX(21px);
}

.fa-moon {
    color: #009ef7;
}

.fa-sun {
    color: #009ef7;
}
/* Switch Mode */

/* Chart Style */
.zc-body {
    background-color: #fff;
}

.chart--container {
    width: 100%;
    height: 100%;
    min-height: 530px;
}

.zc-ref {
    display: none;
}

#myChart-license-text {
    display: none;
}

@media only screen and (max-width: 768px) {
    /* .dashboard--wrapper {
            flex-direction: column;
    } */

    .content-section {
        width: 100%;
    }

    .card--kontak {
        /* height: 70px !important; */
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 425px) {
    /* .card--kontak {
        height: 70px !important;
        margin-bottom: 20px;
    } */
}